import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const Button = ({
  children, variant, href, onClick,
}) => {
  const ButtonType = href ? 'a' : 'button';

  return (
    <ButtonType
      type={href ? undefined : 'button'}
      className={cx('button', `button--${variant}`)}
      onClick={onClick}
      href={href}
    >
      {children}
    </ButtonType>

  );
};

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  onClick: PropTypes.func,
  href: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  variant: 'primary',
  onClick: undefined,
  href: undefined,
};

export default Button;
