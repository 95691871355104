import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from '../Button';
import './styles.scss';
import TextBlock from '../TextBlock';
import Flags from '../../images/flags.png';
import BetterPrideLogo from '../../../better-pride/src/images/better-pride-logo-2.png';
import BetterRelationshipsLogo from '../../../better-relationships/src/images/better-relationships-logo.png';

const Footer = () => (
  <footer className="footer">
    <section className="footer__feedback">
      <div className="footer__feedback__main">
        <div className="footer__feedback__column-1">
          <TextBlock>
            <h2>We want your feedback</h2>

            <p>We greatly appreciate the feedback that community members share about their experiences, where they think Better Place Australia could improve and where they think we have done a great job.</p>
          </TextBlock>
        </div>
        <div className="footer__feedback__column-2">
          <Button href="https://betterplaceaustralia.com.au/feedback/">Provide feedback</Button>
        </div>
      </div>
    </section>

    <section className="footer__bottom">
      <div className="footer__bottom__main">
        <div className="footer__bottom__column-1">
          <TextBlock>
            <img src={Flags} alt="Flags of the Traditional Custodians" />
            <p>Better Place Australia acknowledges the Traditional Custodians and their Elders in each of the communities where we work.</p>

            <div className="hidden-xs">
              <a href="https://betterplaceaustralia.com.au/privacy/" className="footer__link">Privacy policy</a>
            </div>
          </TextBlock>
        </div>
        <div className="footer__bottom__column-2">
          <a href="https://betterpride.com.au">
            <img src={BetterPrideLogo} alt="Better Pride logo" />
            Visit Better Pride
          </a>
        </div>
        <div className="footer__bottom__column-3">
          <a href="https://centreforbetterrelationships.com.au">
            <img src={BetterRelationshipsLogo} alt="Centre for Better Relationships logo" />
            Visit centre for better relationships
          </a>
        </div>
      </div>

      <div style={{ maxWidth: '1200px', margin: '0 auto' }} className="visible-xs">
        <a href="https://betterplaceaustralia.com.au/privacy/" className="footer__link">Privacy policy</a>
      </div>
    </section>
  </footer>
);

Footer.propTypes = {

};

Footer.defaultProps = {

};

export default Footer;
