import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const StaffBlock = ({ children }) => (
  <div className="staff-block">
    {children}
  </div>
);

StaffBlock.propTypes = {
  children: PropTypes.node,
};

StaffBlock.defaultProps = {
  children: null,
};

export default StaffBlock;
