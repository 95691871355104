import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

const TextBlock = ({ children }) => (
  <div className="text-block">
    {children}
  </div>
);

TextBlock.propTypes = {
  children: PropTypes.node,
};

TextBlock.defaultProps = {
  children: null,
};

export default TextBlock;
