import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const CardsBlock = ({ children }) => (
  <div className="cards-block">
    {children}
  </div>
);

CardsBlock.propTypes = {
  children: PropTypes.node,
};

CardsBlock.defaultProps = {
  children: null,
};

export default CardsBlock;
