import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

const ContentBlock = ({ children }) => (
  <div className="content-block">
    {children}
  </div>
);

ContentBlock.propTypes = {
  children: PropTypes.node,
};

ContentBlock.defaultProps = {
  children: null,
};

export default ContentBlock;
