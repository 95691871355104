import React, { useEffect } from 'react';

import Button from '../Button';
import './styles.scss';
import BetterPlaceLogo from '../../images/better-place-logo.png';

const Header = () => {
  useEffect(() => {
    // fixes an issue on Safari where body will still be hidden when user goes back from external source
    document.getElementsByTagName('body')[0].style.display = '';
  }, []);

  const handleQuickExit = () => {
    document.getElementsByTagName('body')[0].style.display = 'none';
    window.location.href = 'https://google.com.au/';
  };

  return (
    <header className="header">
      <div className="header__inner">
        <div className="header__column-1">
          <a href="https://betterplaceaustralia.com.au/">
            <img src={BetterPlaceLogo} alt="Better Place Australia" />
          </a>
        </div>
        <div className="header__quickExit hidden-print">
          <Button
            variant="secondary"
            onClick={handleQuickExit}
          >
          Quick exit
          </Button>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
