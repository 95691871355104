import React from 'react';
import { Link } from 'gatsby';

import './styles.scss';

const Breadrumb = () => (
  <nav aria-label="Breadcrumb" className="breadcrumb">
    <ol>
      <li><Link to="/">Home</Link></li>
    </ol>
  </nav>
);

export default Breadrumb;
