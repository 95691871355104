import React from 'react';
import PropTypes from 'prop-types';

import PdfIcon from '../../images/icon-pdf.svg';
import './styles.scss';

const PdfLink = ({ children, href }) => (
  <a
    href={href}
    className="pdf-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={PdfIcon} alt="PDF icon" />
    <span>{children}</span>
  </a>
);

PdfLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

PdfLink.defaultProps = {
  children: null,
  href: '#',
};

export default PdfLink;
